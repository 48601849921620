import { ProductCard, Text } from '@lounge-fe/ui-kit'
import { CollectionProductsProps } from './types'
import { StandardProductCard } from '@/features/product/components/StandardProductCard'
import { createLiquidExtendedProductHit } from '@/features/product/utils/createExtendedProductHit'

export const CollectionProducts = ({ products }: CollectionProductsProps) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
      {products.map((product) => (
        <>
          <StandardProductCard
            key={product.id}
            product={createLiquidExtendedProductHit(product)}
            useQuickAdd
            showWishlist={false}
          />
        </>
      ))}
    </div>
  )
}
