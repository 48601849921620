import {
  Accordion,
  Divider,
  Link,
  Text,
  useMediaQuery,
} from '@lounge-fe/ui-kit'
import classNames from 'classnames'

import { MainMenuProps } from './types'

export const MainMenu = ({ menus }: MainMenuProps) => {
  const isDesktop = useMediaQuery(({ screens }) => `(min-width: ${screens.lg})`)

  if (isDesktop) {
    return (
      <div className="flex gap-x-20">
        {menus.map((menu, idx) => (
          <div key={idx} className="flex flex-col items-start">
            <Text as="h3" variant="body-lg" className="font-medium mb-4">
              {menu.title}
            </Text>
            <nav>
              <ul className="flex flex-col">
                {menu.links.map((item, idx) => (
                  <li key={idx}>
                    <Link
                      href={item.url}
                      variant="body-sm"
                      className="!font-regular"
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        ))}
      </div>
    )
  }

  return (
    <Accordion>
      {menus.map((menu, idx) => (
        <>
          {idx === 0 && <Divider className="!border-[#E7E7E7]" />}
          <Accordion.Item
            title={
              <Text as="h3" className="font-medium">
                {menu.title}
              </Text>
            }
          >
            <nav>
              <ul className="list-none pb-4">
                {menu.links.map((item, idx) => (
                  <li key={idx}>
                    <Link
                      href={item.url}
                      variant="body-sm"
                      className="!font-regular"
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </Accordion.Item>
          <Divider className="!border-[#E7E7E7]" />
        </>
      ))}
    </Accordion>
  )
}
