import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Heart: React.FC<SvgProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.53032 10.2435L10.0058 17.0147L17.4813 10.2435C19.3464 8.36883 18.9301 5.59169 17.3299 3.99631C16.5405 3.20935 15.4861 2.74109 14.3134 2.82425C13.1407 2.90742 11.7739 3.54809 10.3808 5.1276C10.2859 5.23522 10.1493 5.29687 10.0058 5.29687C9.86231 5.29687 9.72573 5.23522 9.63081 5.1276C8.23778 3.54816 6.86897 2.90539 5.69348 2.82021C4.51799 2.73504 3.46102 3.20133 2.67039 3.98639C1.06866 5.57683 0.650091 8.35371 2.53032 10.2435ZM10.0058 4.06085C8.60131 2.61594 7.14353 1.92266 5.76574 1.82283C4.26487 1.71408 2.93371 2.31568 1.96579 3.27679C0.0528092 5.17628 -0.528588 8.59886 1.83172 10.9592C1.83754 10.965 1.84351 10.9707 1.84961 10.9762L9.67013 18.0599C9.86064 18.2325 10.151 18.2325 10.3415 18.0599L18.162 10.9762C18.1644 10.974 18.1668 10.9718 18.1692 10.9696C18.1728 10.9662 18.1764 10.9627 18.1799 10.9592C20.5271 8.61202 19.9433 5.18973 18.0359 3.28812C17.0702 2.32537 15.7419 1.72044 14.2426 1.82676C12.8664 1.92436 11.41 2.61623 10.0058 4.06085Z"
        fill="currentColor"
      />
    </Icon>
  )
}
