import {
  Button,
  CloseIconButton,
  Text,
  Transition,
  useDisclosure,
} from '@lounge-fe/ui-kit'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSearchParams } from '../../../../hooks/useSearchParams'
import {
  useCurrentStore,
  useSetStore,
  useRecommendedStore,
  useUserStoreCookie,
} from '../../hooks'
import { SelectStoreModal } from '../SelectStoreModal'
import { STORES } from '../../stores'
import { useShopify } from '@/hooks/useShopify'

export const StoreRedirectBanner = () => {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const currentStore = useCurrentStore()
  const recommendedStore = useRecommendedStore()
  const setStore = useSetStore()
  const [userStoreCookie, setUserStoreCookie] = useUserStoreCookie()
  const { searchParams } = useSearchParams()
  const { environment } = useShopify()

  const dismissPopover = () => {
    setStore(currentStore.shop)
    onClose()
  }

  useEffect(() => {
    if (window.zE) {
      if (isOpen) {
        window.zE('messenger', 'hide')
      } else {
        window.zE('messenger', 'show')
      }
    }
  }, [isOpen])

  useEffect(() => {
    // User has cookie
    if (userStoreCookie) {
      // User has clear locale, set the cookie to current store
      if (
        searchParams.has('action') &&
        searchParams.get('action') === 'clear-locale'
      ) {
        setUserStoreCookie(currentStore.shop)
        return
      }

      // User is not on the correct store, so redirect
      if (
        currentStore.shop !== userStoreCookie &&
        environment === 'production'
      ) {
        setStore(userStoreCookie)
      }
      // User is on the correct store, so do nothing
      return
    }

    // User does not have cookie and we have the recommended store
    if (recommendedStore) {
      // User is not on the recommended store, so show banner
      if (currentStore.shop !== recommendedStore.shop) {
        onOpen()
      }
    }
  }, [currentStore, recommendedStore])

  if (!recommendedStore) {
    return null
  }

  return (
    <Transition.Slide
      role="dialog"
      show={isOpen}
      direction="bottom"
      className="fixed right-0 bottom-0 left-0 sm:left-[initial] sm:bottom-8 sm:right-8 bg-white z-20 sm:rounded-md shadow-[0px_0px_16px_4px_#0000001c] flex flex-col justify-center items-center p-6 pb-4 sm:w-[500px]"
    >
      <header className="w-full">
        <Text variant="title-five" className="font-medium mb-3 capitalize">
          {t('localisation.confirm_your_location')}
        </Text>
      </header>
      <div className="flex gap-x-3 w-full mb-6">
        <div>
          <Text variant="body-md">
            <Trans
              i18nKey="localisation.recommended_location_message"
              values={{
                store:
                  recommendedStore.shortName === 'ROW'
                    ? t(`countries.${recommendedStore.shortName}`)
                    : recommendedStore.name,
              }}
              components={{ bold: <strong /> }}
            />
          </Text>
        </div>
        <CloseIconButton
          className="absolute top-6 right-6"
          onClick={dismissPopover}
          aria-label={t('action.close')}
        />
      </div>
      <div className="flex flex-wrap gap-3 w-full mb-2">
        <Button
          variant="outline"
          className="w-full flex-1 whitespace-nowrap"
          startIcon={<currentStore.icon className="w-4 h-4 rounded-circle" />}
          onClick={dismissPopover}
        >
          {currentStore.shortName === 'ROW'
            ? t(`countries.${currentStore.shortName}`)
            : currentStore.shortName}
        </Button>
        <Button
          className="w-full flex-1 whitespace-nowrap"
          onClick={() => setStore(recommendedStore.shop)}
          startIcon={
            <recommendedStore.icon className="w-4 h-4 rounded-circle" />
          }
        >
          {recommendedStore.shortName === 'ROW'
            ? t(`countries.${recommendedStore.shortName}`)
            : recommendedStore.shortName}
        </Button>
      </div>
      <SelectStoreModal>
        <SelectStoreModal.Content
          stores={STORES.filter(
            (store) =>
              ![currentStore.shop, recommendedStore.shop].includes(store.shop)
          )}
        />
        <SelectStoreModal.LinkTrigger>
          {t('localisation.more_locations')}
        </SelectStoreModal.LinkTrigger>
      </SelectStoreModal>
    </Transition.Slide>
  )
}
